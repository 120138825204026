<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('addcommercial_jbxx', false)">基本信息</span>
      <span @click="toUrl('addcommercial_jsxx', false)">结算信息</span>
      <span id="fzcolor">渠道信息</span>
      <span @click="toUrl('addcommercial_pzxx')">产品信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">渠道信息</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>渠道名称</p>
          </td>
          <td>
            <p>渠道编号</p>
          </td>
          <td>
            <p>支付机构</p>
          </td>
          <td>
            <p>渠道类型</p>
          </td>
          <td>
            <p>结算规则</p>
          </td>
          <td>
            <p>商户号</p>
          </td>
          <td>
            <p>密钥</p>
          </td>
          <td>
            <p>渠道状态</p>
          </td>
          <td>
            <p>产品信息</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="padding-left: 10px">
            <p>{{ v.mchChannelDetail.mchChannelName }}</p>
          </td>
          <td>
            <p>{{ v.mchChannelDetail.mchChannelId }}</p>
          </td>
          <td>
            <p>{{ v.mchChannelDetail.orgName }}</p>
          </td>
          <td>
            <p>
              {{
                v.mchChannelDetail.channelType == "SINGLE_MERCHANT"
                  ? "单商户模式"
                  : v.mchChannelDetail.channelType == "SERVICE_MERCHANT"
                  ? "服务商模式"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>
              {{
                v.mchChannelDetail.settleRule == "AUTO"
                  ? "自动结算"
                  : v.mchChannelDetail.settleRule == "AGENT_PAY"
                  ? "代付"
                  : ""
              }}
            </p>
          </td>
          <td>
            <p>{{ v.mchChannelDetail.mchNo }}</p>
          </td>
          <td style="width: 80px">
            <!-- <p class="hidden1" style="width: 150px;">{{v.mchKey.replace(/[\s\S]/g,'*')}}</p> -->
            <p class="hidden1" style="width: 80px">
              {{ v.mchChannelDetail.mchKey ? "******" : "" }}
            </p>
          </td>
          <td>
            <p>
              <el-switch
                v-model="v.mchChannelDetail.channelStatus"
                active-color="#13ce66"
                active-value="ACTIVATED"
                inactive-value="FROZEN"
              >
              </el-switch>
            </p>
          </td>
          <td>
            <p><i class="lianjie" @click="configTab(i)">配置</i></p>
          </td>
          <td>
            <p>
              <i class="lianjie" @click="editTab(v.mchChannelDetail, i)">编辑</i
              ><i class="lianjie" @click="delTab(i)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <h6 class="xzjsxx" @click="addTab"><span>+</span> 新增渠道信息</h6>
    <div class="btn">
      <el-button @click="toUrl('addcommercial_jsxx', false)" class="back"
        >上一步</el-button
      >
      <el-button
        @click="toUrl('addcommercial_pzxx')"
        class="next"
        type="primary"
        >下一步</el-button
      >
    </div>
    <el-dialog
      :title="channelTitle + '渠道基本信息'"
      :visible.sync="infoShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">渠道名称</div>
          <el-input
            v-model="formData.mchChannelName"
            maxlength="128"
            placeholder="同商户名称"
            class="width100"
            disabled
            tabindex="1"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>渠道类型</div>
          <el-select
            v-model="formData.channelType"
            placeholder="选择渠道类型"
            class="width100"
            tabindex="3"
          >
            <el-option label="单商户模式" value="SINGLE_MERCHANT"></el-option>
            <el-option label="服务商模式" value="SERVICE_MERCHANT"></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>商户号</div>
          <el-input
            v-model="formData.mchNo"
            maxlength="128"
            placeholder="输入商户号"
            class="width100"
            tabindex="5"
          ></el-input>
          <div class="overAccount_ul_title">商户RSA公钥</div>
          <el-input
            v-model="formData.mchPublicKey"
            maxlength="2048"
            placeholder="输入商户RSA公钥"
            class="width100"
            tabindex="7"
          ></el-input>
          <div class="overAccount_ul_title">机构RSA公钥</div>
          <el-input
            v-model="formData.orgPublicKey"
            maxlength="2048"
            placeholder="输入机构RSA公钥"
            class="width100"
            tabindex="9"
          ></el-input>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>所属机构</div>
          <el-select
            v-model="formData.orgId"
            placeholder="选择所属机构"
            class="width100"
            tabindex="2"
            :disabled="formData.changeOrg == true ? false : true"
          >
            <el-option
              v-for="(v, i) in organizationsLists"
              :key="i"
              :label="v.orgName"
              :value="v.orgId"
            >
            </el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>结算规则</div>
          <el-select
            v-model="formData.settleRule"
            placeholder="选择结算规则"
            class="width100"
            tabindex="4"
          >
            <el-option label="自动结算" value="AUTO"></el-option>
            <el-option label="代付" value="AGENT_PAY"></el-option>
          </el-select>
          <div class="overAccount_ul_title"><i>*</i>密钥</div>
          <el-input
            type="password"
            v-model="formData.mchKey"
            maxlength="2048"
            placeholder="输入密钥"
            class="width100"
            tabindex="6"
          ></el-input>
          <div class="overAccount_ul_title">商户RSA私钥</div>
          <el-input
            v-model="formData.mchPrivateKey"
            maxlength="2048"
            placeholder="输入商户RSA私钥"
            class="width100"
            tabindex="8"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="infoShow = false">取 消</el-button>
        <el-button class="addBt" :disabled="buttonFlag" @click="sureAdd"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { organizationsDrop } from "@/api/common.js";
import md5 from "js-md5";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      infoShow: false, //新增/修改渠道基本信息展示
      channelTitle: "新增", //新增或编辑渠道信息弹框标题
      formData: {
        mchChannelName: "", //true	商户渠道名称
        orgId: "", //true	所属机构ID
        channelType: "", //true	渠道类型
        settleRule: "", //true	结算规则
        mchNo: "", //true	商户号
        mchKey: "", //true 商户key
        mchPublicKey: "",
        mchPrivateKey: "",
        orgPublicKey: "",
        changeOrg:true,
      },
      organizationsLists: [], //支付机构列表
      tabData: [],
      editIndex: "",
      md5,
      mchDetail: "",
      buttonFlag: false, //防止重复点击
    };
  },
  computed: {
    ...mapState({
      businessDetail: "user_businessDetail",
    }),
  },
  mounted() {
    if (this.businessDetail) {
      this.tabData = this.businessDetail.mchChannels
        ? this.businessDetail.mchChannels
        : [];
      this.mchDetail = this.businessDetail.mchDetail
        ? this.businessDetail.mchDetail
        : [];
      if(this.tabData != ''){
        for(let i=0 ; i< this.tabData.length ; i++)
        this.tabData[i].mchChannelDetail.mchChannelName = this.mchDetail.mchName;
      }
      console.log(this.tabData,"渠道信息")
    }
    this.getOrganizationsLists();
  },
  methods: {
    ...mapMutations({
      setBusiness: "user_setBusiness",
    }),
    // 获取支付机构列表
    getOrganizationsLists() {
      organizationsDrop().then((res) => {
        this.organizationsLists = res.resultData;
      });
    },
    // 点击 新增渠道基本信息
    addTab() {
      this.formData = {
        mchChannelName: this.mchDetail.mchName,
        orgId: "",
        channelType: "",
        settleRule: "",
        mchNo: "",
        mchKey: "",
        mchPublicKey: "",
        mchPrivateKey: "",
        orgPublicKey: "",
        changeOrg:true,
      };
      this.editIndex = null;
      this.channelTitle = "新增";
      this.infoShow = true;
    },
    // 编辑渠道基本信息
    editTab(formData, index) {
      if(this.businessDetail.mchChannels != null){
        let payProducts = this.businessDetail.mchChannels[index].payProducts;
        let agnpayProducts = this.businessDetail.mchChannels[index].agnpayProducts;
        let allocProducts = this.businessDetail.mchChannels[index].allocProducts;
        if(payProducts.length>0 || agnpayProducts.length>0 || allocProducts.length>0){
          formData.changeOrg = false;
        }else{
          formData.changeOrg = true;
        }
      }
      this.formData = JSON.parse(JSON.stringify(formData));
      this.editIndex = index;
      this.channelTitle = "编辑";
      this.infoShow = true;
    },
    //删除结算账户
    delTab(index) {
      this.$confirm("此操作将删除该配置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tabData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认添加结算账户
    sureAdd() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.orgId) {
        this.$message.error("所属机构必选");
        return false;
      }
      if (!data.channelType) {
        this.$message.error("渠道类型必选");
        return false;
      }
      if (!data.settleRule) {
        this.$message.error("结算规则必选");
        return false;
      }
      if (!data.mchNo) {
        this.$message.error("商户号必填");
        return false;
      }
      if (!data.mchKey) {
        this.$message.error("密钥必填");
        return false;
      }
      this.organizationsLists.forEach((v) => {
        if (v.orgId == data.orgId) {
          data.orgName = v.orgName;
        }
      });
      this.buttonFlag = true;

      if (this.editIndex !== null) {
        this.tabData[this.editIndex].mchChannelDetail = data;
        this.tabData = JSON.parse(JSON.stringify(this.tabData));
        this.buttonFlag = false;
      } else {
        this.tabData.push({
          mchChannelDetail: data,
          payProducts: [],
          agnpayProducts: [],
          allocProducts: [],
        });
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000);
      }
      this.$message.success("保存成功");
      // sessionStorage.setItem('mchChannels', JSON.stringify(this.tabData))
      this.infoShow = false;
    },
    // 页面跳转
    toUrl(name, bool = true) {
      if (bool) {
        if (this.tabData.length <= 0) {
          this.$message.error("至少添加一个配置");
          return false;
        }
      }
      // sessionStorage.setItem('mchChannels', JSON.stringify(this.tabData))
      this.$router.push({
        name: name,
      });
    },
    // 渠道配置
    configTab(index) {
      this.$router.push({
        name: "ditch_pz",
        query: {
          id: index,
        },
      });
    },
  },
  beforeDestroy() {
    let data = {
      mchDetail: this.businessDetail ? this.businessDetail.mchDetail : null,
      settlements: this.businessDetail ? this.businessDetail.settlements : null,
      mchChannels: this.tabData,
    };
    this.setBusiness(data); 
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}

.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}


.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}


.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
